<template>
  <div class="surface-ground px-4 py-5 md:px-6 lg:px-8">
    <div class="grid">
      <div class="col-12 lg:col-4 p-3">
        <div class="shadow-2 border-round surface-card mb-3 h-full flex-column justify-content-between flex">
          <div class="p-4">
            <div class="flex align-items-center bottom-hr">
                        <span class="inline-flex border-circle align-items-center justify-content-center bg-primary-700 mr-3" style="width:38px;height:38px">
                            <i class="pi pi-book text-xl text-primary-300"></i>
                        </span>
              <span class="text-900 font-medium text-2xl">Lastest news</span>
            </div>
            <div class="text-900 my-3 text-xl font-medium">Notice of special general meeting 2022</div>
            <p class="mt-0 mb-3 text-700 line-height-3">Eget sit amet tellus cras adipiscing enim. At quis risus sed vulputate odio. Proin libero nunc consequat interdum varius sit amet.</p>
          </div>
          <div class="px-4 py-3 surface-100 text-right">
            <Button icon="pi pi-arrow-right" label="Read" class="p-button-rounded p-button-primary"></Button>
          </div>
        </div>
      </div>
      <div class="col-12 lg:col-4 p-3">
        <div class="shadow-2 border-round surface-card mb-3 h-full flex-column justify-content-between flex">
          <div class="p-4">
            <div class="flex align-items-center bottom-hr">
                        <span class="inline-flex border-circle align-items-center justify-content-center bg-primary-700 mr-3" style="width:38px;height:38px">
                            <i class="pi pi-globe text-xl text-primary-300"></i>
                        </span>
              <span class="text-900 font-medium text-2xl">Latest event</span>
            </div>
            <div class="text-900 my-3 text-xl font-medium">Working bee this December</div>
            <p class="mt-0 mb-3 text-700 line-height-3">Molestie ac feugiat sed lectus vestibulum mattis ullamcorper. Vulputate odio ut enim blandit volutpat maecenas. Convallis tellus id interdum velit.</p>
          </div>
          <div class="px-4 py-3 surface-100 text-right">
            <Button icon="pi pi-arrow-right" label="Read" class="p-button-rounded p-button-primary"></Button>
          </div>
        </div>
      </div>
      <div class="col-12 lg:col-4 p-3">

          <div class="surface-card border-round shadow-2 p-4 text-center mb-3 h-full flex-column justify-content-between flex">
<!--            <img src="images/blocks/illustration/subscribe.svg" alt="Image" class="mx-auto block mb-4">-->
            <div class="text-900 font-medium mb-3 text-xl">All news and events</div>
            <span class="inline-flex align-items-center justify-content-center mr-3">
              <div class="border-circle bg-primary-300 hero-icon-bg">
                <i class="pi pi-globe text-primary-700 mx-auto block hero-icon"></i>
              </div>
            </span>

<!--            <p class="mt-0 mb-4 p-0 line-height-3">Nunc mi ipsum faucibus vitae aliquet nec. Lacus sed viverra tellus in hac habitasse platea dictumst.</p>-->
            <Button label="See all news and events" icon="pi pi-arrow-right" class="p-button-rounded p-button-primary" iconPos="right"></Button>
          </div>

      </div>
    </div>
  </div>


</template>

<script>
export default {
  name: 'LatestNewsAndEventsPanel',
  props: {
    imageSrc: String,
    mainTitle: String,
    subTitle: String,
  },

  components: {

  },

  data() {
    return {

    }
  },

  methods: {},

  computed: {},

  watch: {}

}
</script>


<style scoped>
  .hero-icon{
    font-size: 100px;
  }

  .hero-icon-bg{
    padding: 20px;
  }

  .bottom-hr{
    border-bottom: 1px solid var(--surface-border);
    padding-bottom: 16px;
  }

</style>
