
<template>

  <!--  https://communitylaw.org.nz/community-law-manual/chapter-9-privacy-and-information/requirements-about-storing-information-and-keeping-it-secure/-->

  <standard-wrapper :key="this.$store.state.userIsAuthenticated">

    <hero-image :image-src="bannerImage" main-title="Join the Tumapuhia hapu" sub-title="And become a part of our community."></hero-image>

    <div class="surface-ground px-4 py-8 md:px-6 lg:px-8 text-700">
      <div class="flex flex-wrap">
        <div class="w-full xl:w-5 pr-0 lg:pr-5">
          <div class="font-normal text-2xl mt-3 mb-3 text-900">Why register with Tūmapūhia Marae?</div>
          <p class="line-height-3 mt-0 mb-5">
            As a member of the Tūmapūhia hapū, registering with the Marae offers a range of benefits and opportunities for you to connect with your community and learn about your culture and heritage. By registering, you will be able to access exclusive resources and services, such as the document management system and the genealogy and history section of the website. You will also be able to participate in community events and activities, and have a voice in the decision-making process of the Marae.
          </p>
          <p class="line-height-3  mt-0 mb-5">
            In addition to the personal benefits of registering with Tūmapūhia Marae, your registration also helps to support the ongoing work and mission of the Marae. By registering, you are helping to preserve and celebrate the culture and heritage of Tūmapūhia hapū.
          </p>
        </div>
        <div class="w-full xl:w-2 xl:px-5 xl:py-5 hidden xl:block">
          <Divider layout="vertical" />
        </div>
        <div class="w-full xl:w-5 pr-0 lg:pr-5">
          <div class="font-normal text-2xl mt-3 mb-3 text-900">What do I need to register with Tūmapūhia Marae?</div>
          <p class="line-height-3 mt-0 mb-5">
            To register with Tūmapūhia Marae, you will need to provide some basic personal information, such as your name, address, and contact details. You will also need to provide proof of your membership in the Tūmapūhia hapū, such as a certificate of ancestry or a letter from the Marae confirming your membership. In some cases, additional documentation may be required, such as proof of address or identification.
          </p>
          <p class="line-height-3 mt-0 mb-5">
            Once you have gathered all the required information and documentation, you can register with Tūmapūhia Marae by completing the registration form on the website. The form can be accessed from at the bottom of this page, and can be completed online in a few simple steps. Once your registration has been reviewed and approved by an administrator, you will be able to access the full range of resources and services offered by the Marae.
          </p>
        </div>
      </div>
    </div>


    <div class="surface-ground px-4 pb-8 md:px-6 lg:px-8">
      <div class="grid">

        <div class="surface-card col-12 border-round p-4 mb-6 shadow-2">
          <div class="text-900 font-medium text-xl mb-3">Registration form</div>
          <p class="text-600 my-0 mb-4 pb-3 border-bottom-1 surface-border">Fill out and submit the form below</p>

          <div class="grid mb-3">
            <div class="col-12">
              <div class="grid formgrid p-fluid">
                <div class="field mt-4 col-12 md:col-6">
                  <label for="firstName" class="font-medium text-900">Ingoa Tuatahi – First Name(s)<span class="required-asterisk">*</span></label>
                  <InputText id="firstName" type="text" />
                </div>
                <div class="field mt-4 col-12 md:col-6">
                  <label for="preferredName" class="font-medium text-900">Ingoa Anō – Also Known As</label>
                  <InputText id="preferredName" type="text" />
                </div>
                <div class="field mt-4 col-12 md:col-6">
                  <label for="middleName" class="font-medium text-900">Ingoa Whānau – Family Name (Surname)<span class="required-asterisk">*</span></label>
                  <InputText id="middleName" type="text" />
                </div>
                <div class="field mt-4 col-12 md:col-6">
                  <label for="lastName" class="font-medium text-900">Karanga Tūturu – Maiden Name</label>
                  <InputText id="lastName" type="text" />
                </div>

                <div class="field mt-4 col-12 md:col-6">
                  <label for="dateOfBirth" class="font-medium text-900">Rā Whānau – Date of Birth<span class="required-asterisk">*</span></label>
                  <InputMask id="dateOfBirth" mask="99/99/9999" placeholder="dd/mm/yyyy" slotChar="dd/mm/yyyy" />
                </div>

                <div class="field mt-4 col-12 md:col-6">
                  <label for="placeOfBirth" class="font-medium text-900">Mahi – Occupation</label>
                  <InputText id="placeOfBirth" type="text" />
                </div>

                <div class="field mt-4 col-12 md:col-6">
                  <label for="gender" class="font-medium text-900">Gender<span class="required-asterisk">*</span></label>
                  <Dropdown v-model="selectedGender" :options="genders" optionLabel="name" optionValue="name" placeholder="Please choose" />
                </div>

                <div class="field mt-4 col-12 md:col-6">
                  <label for="placeOfBirth" class="font-medium text-900">Direct descendant of hapu?<span class="required-asterisk">*</span></label>
                  <Dropdown v-model="selectedDecendent" :options="decendentOptions" optionLabel="name" optionValue="name" placeholder="Please choose" />
                </div>


                <div class="field mt-4 col-12 md:col-6">
                  <label for="placeOfBirth" class="font-medium text-900">Wāhi Kāinga – Home Address</label>
                  <Textarea v-model="value2" :autoResize="true" rows="5" cols="30" />
                </div>

                <div class="mt-5 col-12 md:col-6">
                  <div class="grid">
                      <div class="field col-12">
                        <label for="placeOfBirth" class="font-medium text-900">Wāea – Phone</label>
                        <InputText id="placeOfBirth" type="text" />
                      </div>
                      <div class="field col-12 mt-4 md:mt-0">
                        <label for="placeOfBirth" class="font-medium text-900">Īmera - Email<span class="required-asterisk">*</span></label>
                        <InputText id="placeOfBirth" type="text" />
                      </div>
                  </div>
                </div>
                <div class="field mt-4 col-4 hidden md:block">
                </div>
                <div class="field mt-4 col-12 md:col-4">
                  <Button label="Submit Form" @click="sumbitForm" />
                </div>
                <div class="field mt-4 col-4 hidden md:block">
                </div>

              </div>
            </div>
            <div v-if="formSubmitted" class="col-12">
              <div class="font-normal text-2xl mt-3 mb-3 text-900">Thank-you. We will contact you soon.</div>
              <p class="line-height-3 mt-0 mb-5">
                Nisi est sit amet facilisis. Ac odio tempor orci dapibus ultrices in iaculis nunc sed. Pellentesque pulvinar pellentesque habitant morbi tristique senectus.
                Nullam vehicula ipsum a arcu cursus vitae congue. Leo urna molestie at elementum eu facilisis. Fusce id velit ut tortor. Purus non enim praesent elementum facilisis leo vel fringilla est.
                Semper risus in hendrerit gravida rutrum quisque non tellus orci. Neque convallis a cras semper. Vitae elementum curabitur vitae nunc sed. Ornare massa eget egestas purus viverra accumsan in.
                Leo integer malesuada nunc vel risus commodo viverra maecenas accumsan. Sit amet risus nullam eget. Egestas maecenas pharetra convallis posuere morbi leo urna molestie at.
              </p>
            </div>

          </div>


        </div>
      </div>
    </div>

  </standard-wrapper>
</template>


<script>

import StandardWrapper from "@/components/StandardWrapper";
import HeroImage from "@/components/HeroImage";


export default {
  name: 'RegistrationPage',

  props: {},

  components: {
    StandardWrapper, HeroImage
  },

  mounted(){
  },


  data() {
    return {
      bannerImage: require("../assets/images/hero-images/marae-banner-placeholder.jpg"),

      formStep: 1,
      formSubmitted: 0,


      genders: [{name: "Male"}, {name: "Female"}],
      decendentOptions: [{name: "Yes, I am a direct descendant"}, {name: "No, I am not a direct descendant"}, {name: "I am not sure"}],
      selectedGender: null,
      selectedDecendent: null,

      identificationUploadFilename: "",
      extraUploadFilename: "",

    }
  },

  methods: {

    sumbitForm: function(){
      this.formSubmitted = 1;
    },

    uploadIdentification: function(){
      this.identificationUploadFilename = "Test_file_1.pdf";
    },

    uploadExtra: function(){
      this.extraUploadFilename = "Test_file_2.pdf";
    }
  },

  computed: {

  },

  watch: {}

}

</script>

<style scoped>

.required-asterisk{

}

</style>
