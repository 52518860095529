<template>

  <div style="width: 100%">
    <full-calendar :options="options" ></full-calendar>
  </div>

</template>

<script>

import '@fullcalendar/core/vdom'; // solves problem with Vite
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';

export default {
  name: 'BookingCalendar',
  props: {},

  mounted() {
    this.todayDateString = this.GetTodayDateString();
  },

  components: {
    FullCalendar
  },

  data() {
    return {

      todayDateString: "2019-04-01",

      test: [{title:'Something', start:'2017-02-01'}],


      options: {
        plugins:[dayGridPlugin, timeGridPlugin, interactionPlugin],
        initialDate : this.todayDateString,
        events: [
          { title: '', date: '2022-10-17', display: 'background', backgroundColor: "#FF0000"},
          { title: '', date: '2022-10-18', display: 'background', backgroundColor: "#FF0000"},
          { title: '', date: '2022-10-19', display: 'background', backgroundColor: "#FF0000"},
          { title: '', date: '2022-10-12', display: 'background', backgroundColor: "#FF0000"},
          { title: '', date: '2022-10-20', display: 'background', backgroundColor: "#FF0000"},
          { title: '', date: '2022-10-01', display: 'background', backgroundColor: "#FF0000" },
          { title: '', date: '2022-10-06', display: 'background', backgroundColor: "#FF0000" },
        ],
        headerToolbar: {
          left: 'title',
          center: '',
          right: 'prev,next'
        },
        editable: true,
        selectable:true,
        selectMirror: true,
        dayMaxEvents: true
      },
    }
  },

  methods: {
    GetTodayDateString() {
      const date = new Date();

      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    }

  },

  computed: {},

  watch: {}

}
</script>


<style>


</style>
