import { createApp } from 'vue';
import { createWebHistory, createRouter } from 'vue-router';
import axios from 'axios';
import { createStore } from 'vuex';

import 'primeflex/primeflex.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import '../node_modules/prismjs/themes/prism-coy.css';
import "primevue/resources/themes/saga-green/theme.css"; //theme
import 'swiper/css';


import PrimeVue from "primevue/config";
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import BadgeDirective from 'primevue/badgedirective';
import Badge from 'primevue/badge';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import Carousel from 'primevue/carousel';
import Checkbox from 'primevue/checkbox';
import Chip from 'primevue/chip';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import FileUpload from 'primevue/fileupload';
import Galleria from 'primevue/galleria';
import InlineMessage from 'primevue/inlinemessage';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Knob from 'primevue/knob';
import Menu from 'primevue/menu';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import Password from 'primevue/password';
import ProgressBar from 'primevue/progressbar';
import RadioButton from 'primevue/radiobutton';
import Rating from 'primevue/rating';
import Ripple from 'primevue/ripple';
import Sidebar from 'primevue/sidebar';
import Slider from 'primevue/slider';
import SelectButton from 'primevue/selectbutton';
import StyleClass from 'primevue/styleclass';
import TabMenu from 'primevue/tabmenu';
import TabPanel from 'primevue/tabpanel';
import TabView from 'primevue/tabview';
import Tag from 'primevue/tag';
import Textarea from 'primevue/textarea';
import Paginator from 'primevue/paginator';
import ToggleButton from 'primevue/togglebutton';
import Timeline from 'primevue/timeline';
import Tooltip from 'primevue/tooltip';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';



import App from './App.vue';
import HomePage from './views/HomePage.vue';
import PeoplePage from "@/views/PeoplePage";
import BookingPage from "@/views/BookingPage";
import RegistrationPage from "@/views/RegistrationPage";
import NewsAndEventsPage from "@/views/NewsAndEventsPage";
import NewsAndEventsDetail from "@/views/NewsAndEventsDetail";

const routes = [
    {
        path: "/",
        component: HomePage,
        name: "default"
    },
    {
        path: "/home",
        component: HomePage,
        name: "HomePage"
    },
    {
        path: "/people",
        component: PeoplePage,
        name: "PeoplePage"
    },
    {
        path: "/news-and-events",
        component: NewsAndEventsPage,
        name: "NewsAndEventsPage"
    },
    {
        path: "/news-and-events/:slug",
        component: NewsAndEventsDetail,
        name: "NewsAndEventsDetail"
    },
    {
        path: "/booking",
        component: BookingPage,
        name: "BookingPage"
    },
    {
        path: "/registration",
        component: RegistrationPage,
        name: "RegistrationPage"
    },
    {
        path: "/login",
        component: HomePage,
        name: "LoginPage"
    },


];

const router = createRouter({
    mode: "hash",
    routes,
    history: createWebHistory()
});


// Create a new store instance.
const store = createStore({
    state () {
        return {
            userIsAuthenticated: false,
        }
    },
    mutations: {
        login(state) {
            alert("You are now logged in as a test user.")
            state.userIsAuthenticated = true;
        },
        logout(state) {
            alert("You are now logged out.")
            state.userIsAuthenticated = false;
        }
    }
})


const app = createApp(App);
app.use(ToastService);
app.use(router);
app.use(store);
app.use(PrimeVue, {ripple: true});
app.directive('ripple', Ripple);
app.directive('styleclass', StyleClass);

app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('Avatar', Avatar);
app.component('AvatarGroup', AvatarGroup);
app.component('Badge', Badge);
app.component('Button', Button);
app.component('Calendar', Calendar);
app.component('Carousel', Carousel);
app.component('Checkbox', Checkbox);
app.component('Chip', Chip);
app.component('Dialog', Dialog);
app.component('Divider', Divider);
app.component('Dropdown', Dropdown);
app.component('FileUpload', FileUpload);
app.component('Galleria', Galleria);
app.component('InlineMessage', InlineMessage);
app.component('InputMask', InputMask);
app.component('InputNumber', InputNumber);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
app.component('Knob', Knob);
app.component('Menu', Menu);
app.component('Message', Message);
app.component('MultiSelect', MultiSelect);
app.component('Password', Password);
app.component('ProgressBar', ProgressBar);
app.component('RadioButton', RadioButton);
app.component('Rating', Rating);
app.component('Sidebar', Sidebar);
app.component('SelectButton', SelectButton);
app.component('Slider', Slider);
app.component('TabMenu', TabMenu);
app.component('TabPanel', TabPanel);
app.component('Timeline', Timeline);
app.component('TabView', TabView);
app.component('Tag', Tag);
app.component('Paginator', Paginator);
app.component('Textarea', Textarea);
app.component('ToggleButton', ToggleButton);
app.component('Toast', Toast);


app.config.globalProperties.axios = axios;
app.mount('#app');
