<template>

  <div class="px-4 py-8 md:px-6 lg:px-8">
    <div class="text-700 text-center">
      <div class="text-900 font-bold text-xl mb-3">Join Our Hapū</div>
      <div class="text-700 text-lg mb-5">Registration is an opportunity for whānau, hapū and iwi to reconnect or stay connected with the Marae.</div>
      <Button label="Register" icon="pi pi-user" class="font-bold px-5 py-3 p-button p-button-rounded"></Button>
    </div>
  </div>


</template>

<script>


export default {
  name: 'RegisterCta',
  props: {},

  components: {},

  data() {
    return {

    }
  },

  methods: {},

  computed: {},

  watch: {}

}
</script>


<style scoped>

.bottom-margin{
  margin-bottom: 29px;
}
</style>
