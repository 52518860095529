<template>
  <div class="min-h-screen flex flex-column">
    <div class="bg-primary-800" style="height:180px">
      <div class="py-3 px-5 flex align-items-center justify-content-between relative lg:static" style="min-height: 80px">
        <router-link to="/">
          <img :src="logoSmall" alt="Tūmapūhia Logo" height="40" class="mr-0 lg:mr-6">
        </router-link>
        <a v-ripple class="cursor-pointer block lg:hidden text-gray-300 p-ripple"
           v-styleclass="{ selector: '@next', enterClass: 'hidden', leaveToClass: 'hidden', hideOnOutsideClick: true }">
          <i class="pi pi-bars text-4xl"></i>
        </a>
        <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full bg-primary-800 left-0 top-100 z-20 text-sm">
          <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
            <li>
              <router-link to="/" v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-300 hover:text-white hover:bg-primary-700 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple menu-item">
                <!--                <i class="pi pi-users mr-2"></i>-->
                <span>Home</span>
              </router-link>
            </li>
            <li>
              <router-link to="/people" v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-300 hover:text-white hover:bg-primary-700 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple menu-item">
<!--                <i class="pi pi-users mr-2"></i>-->
                <span>People</span>
              </router-link>
            </li>
            <li>
              <router-link to="/news-and-events" v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-300 hover:text-white hover:bg-primary-700 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple menu-item">
<!--                <i class="pi pi-book mr-2"></i>-->
                <span>News & Events</span>
              </router-link>
            </li>
            <li>
              <router-link to="/booking" v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-300 hover:text-white hover:bg-primary-700 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple menu-item">
<!--                <i class="pi pi-calendar mr-2"></i>-->
                <span>Booking</span>
              </router-link>
            </li>
            <li>
              <router-link to="/registration" v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-300 hover:text-white hover:bg-primary-700 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple menu-item">
<!--                <i class="pi pi-id-card mr-2"></i>-->
                <span>Registration</span>
              </router-link>
            </li>

            <li>
              <a v-if="this.$store.state.userIsAuthenticated" v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center font-medium border-round transition-colors transition-duration-150 p-ripple text-gray-300 hover:text-white hover:bg-primary-700 cursor-pointer"
                 v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }">
                <i class="pi pi-lock-open mr-2"></i>

                <span>hapū Area</span>
                <i class="pi pi-angle-down ml-auto lg:ml-3"></i>
              </a>

              <a v-else v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center font-medium border-round transition-colors transition-duration-150 p-ripple text-gray-500 cursor-not-allowed">
                <i class="pi pi-lock mr-2"></i>
                <span>hapū Area</span>
                <i class="pi pi-angle-down ml-auto lg:ml-3"></i>
              </a>

              <ul class="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:absolute bg-primary-800 hidden origin-top w-full lg:w-15rem cursor-pointer lg:border-1 border-primary-700">
                <li>
                  <a v-ripple class="flex p-3 align-items-center text-gray-300 hover:text-white hover:bg-primary-700 transition-colors transition-duration-150 p-ripple">
                    <i class="pi pi-file mr-2"></i>
                    <span class="font-medium">Documents</span>
                  </a>
                </li>
                <li>
                  <a v-ripple class="flex p-3 align-items-center text-gray-300 hover:text-white hover:bg-primary-700 transition-colors transition-duration-150 p-ripple">
                    <i class="pi pi-sitemap mr-2"></i>
                    <span class="font-medium">Whakapapa</span>
                  </a>
                </li>
                <li>
                  <a v-ripple class="flex p-3 align-items-center text-gray-300 hover:text-white hover:bg-primary-700 transition-colors transition-duration-150 p-ripple">
                    <i class="pi pi-comments mr-2"></i>
                    <span class="font-medium">History</span>
                  </a>
                </li>
              </ul>
            </li>

          </ul>
          <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 border-primary-700 lg:border-top-none">
            <li v-if="this.$store.state.userIsAuthenticated">
              <router-link to="/" @click="this.$store.commit('logout')" class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-300 hover:text-white hover:bg-primary-700 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple menu-item">
                <i class="pi pi-sign-out mr-2"></i>
                <span>Logout</span>
              </router-link>
            </li>
            <li v-else>
              <router-link to="/login" @click="this.$store.commit('login')" class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-300 hover:text-white hover:bg-primary-700 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple menu-item">
                <i class="pi pi-sign-in mr-2"></i>
                <span>Login</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="md:pt-5 md:pl-5 md:pr-5 flex flex-column flex-auto wrapper-negative-m-top">
      <div class="border-2 surface-border border-round surface-ground flex-auto wrapper-padding content-wrapper">
        <slot></slot>
    </div>
  </div>

    <!-- Begin footer -->
    <div class="footer-wrapper">
      <div class="bg-primary-800 little-spacer"></div>
      <div class="bg-primary-800 footer-border" :style="'background-image: url(\'' + whiteBorder + '\');'"></div>
      <div class="bg-primary-800 px-4 py-8 md:px-6 lg:px-8">
        <div class="grid">
          <div class="col-12 md:col-3 flex flex-wrap justify-content-start md:mb-0 mb-3">
            <img :src="logoSmall" alt="Tūmapūhia hapū Logo" class="footer-logo">
          </div>
          <div class="col-12 md:col-3 text-gray-200">
            <div class="text-white font-bold line-height-3 mb-3">Contact</div>
            <a class="line-height-3 block cursor-pointer mb-2">...</a>
            <a class="line-height-3 block cursor-pointer mb-2">...</a>
            <a class="line-height-3 block cursor-pointer mb-2">....</a>
          </div>
          <div class="col-12 md:col-3 text-gray-200">
            <div class="text-white font-bold line-height-3 mb-3">Site</div>
            <a class="line-height-3 block cursor-pointer mb-2">Home</a>
            <a class="line-height-3 block cursor-pointer mb-2">People</a>
            <a class="line-height-3 block cursor-pointer mb-2">News and Events</a>
            <a class="line-height-3 block cursor-pointer mb-2">Booking</a>
            <a class="line-height-3 block cursor-pointer">Registration</a>
          </div>
          <div class="col-12 md:col-3 text-gray-200">
            <div class="text-white font-bold line-height-3 mb-3">hapū Area</div>
            <a class="line-height-3 block cursor-pointer mb-2">Documents</a>
            <a class="line-height-3 block cursor-pointer mb-2">Whakapapa</a>
            <a class="line-height-3 block cursor-pointer">History</a>
          </div>
        </div>
      </div>
    </div>

  </div>


</template>

<script>
export default {
  name: 'StandardWrapper',
  props: {},

  components: {

  },

  data() {
    return {
      logoSmall: require("../assets/images/brand/logo.png"),
      whiteBorder: require("../assets/images/brand/border-white.png"),
    }
  },

  methods: {},

  computed: {},

  watch: {}

}
</script>


<style scoped>

  @media screen and (max-width: 990px) {
    .wrapper-negative-m-top{
      margin-top: -6rem;
    }
  }
  @media screen and (min-width: 991px) {
    .wrapper-negative-m-top{
      margin-top: -8rem
    }
  }

  .cursor-default{
    cursor: default;
  }

  .cursor-not-allowed{
    cursor: not-allowed;
  }

  .menu-item{
    text-decoration: none;
  }

  .footer-border {
    background-repeat-y: no-repeat;
    height: 23px;
    background-position-y: center;
    background-size: contain;
    width: 100%;
  }

  .footer-logo{
    max-height: 60px;
    max-width: 100%;
  }

  .little-spacer{
    height: 6px;
    width: 100%;
  }

  .contgent-wrapper{
    position: relative;
  }

  .footegr-wrapper{
    position: absolute;
    bottom: 0;
    width: 100%;
  }
</style>
