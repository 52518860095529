<template>

  <div class="hero-image expanded" :style="'background-image: url(\'' + imageSrc + '\');'">
    <div class=" px-4 py-8 md:px-6 lg:px-8">
      <div class="font-bold text-900 text-5xl mb-3 text-center">{{ mainTitle }}</div>
      <div class="text-xl line-height-3 text-600 mb-6 text-center">{{ subTitle }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeroImage',
  props: {
    imageSrc: String,
    mainTitle: String,
    subTitle: String,
    expandToCoverBorder: Boolean,
  },

  components: {

  },

  data() {
    return {

    }
  },

  methods: {},

  computed: {

  },

  watch: {}

}
</script>


<style scoped>

.hero-image {
  /* Set a specific height */
  height: 380px;

  /* Position and center the image to scale nicely on all screens */
  background-position: 50% 60%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  border-radius: 3px;
}

.expanded{
  margin: -2px;
}



</style>
