<template>

  <div class="surface-ground px-4 py-2">
    <div class="surface-card shadow-2 border-round p-4">
      <div class="text-xl text-900 font-medium mb-5">Latest from Events</div>
      <ul class="list-none p-0 m-0">
        <li class="pb-3 border-bottom-1 surface-border">
          <img :src="squarePlaceholder" alt="Image" class="border-round-left feature-image" style="max-height: 234px;">
          <div class="font-medium text-900 mb-2">Aenean euismod elementum</div>
          <div class="line-height-3 text-600" style="max-width: 30rem">Vitae turpis massa sed elementum tempus egestas sed sed risus.
            In metus vulputate eu scelerisque felis imperdiet proin.</div>
        </li>
        <li class="py-3 border-bottom-1 surface-border">
          <div class="font-medium text-900 mb-2">In iaculis nunc sed augue lacus</div>
          <div class="line-height-3 text-600" style="max-width: 30rem">Viverra vitae congue. Nisi scelerisque eu ultrices vitae auctor eu augue ut lectus.
            Elementum eu facilisis sed odio morbi.</div>
        </li>
        <li class="py-3 border-bottom-1 surface-border">
          <div class="font-medium text-900 mb-2">Proin sagittis nisl rhoncus</div>
          <div class="line-height-3 text-600" style="max-width: 30rem">In pellentesque massa placerat duis ultricies lacus. Ac feugiat sed lectus vestibulum mattis ullamcorper.</div>
        </li>
      </ul>
      <div class="flex justify-content-between pt-3">
        <Button label="See more" class="p-button-outlined w-12 mx-2"></Button>
      </div>
    </div>
  </div>

</template>

<script>


export default {
  name: 'NewsWidget',
  props: {},

  components: {},

  data() {
    return {
      squarePlaceholder: require("../assets/images/hero-images/marae-banner-placeholder.png")
    }
  },

  methods: {},

  computed: {},

  watch: {}

}
</script>


<style scoped>

.feature-image{
  width: 100%;
  margin-bottom: 1em;
}

</style>
