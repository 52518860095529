<template>
  <standard-wrapper :key="this.$store.state.userIsAuthenticated">
    <hero-image :image-src="bannerImage" main-title="Stay up-to-date with Tūmapūhia Marae" sub-title="Discover upcoming events and activities, and learn about our recent news"></hero-image>

    <div class="surface-section px-4 py-8 md:px-6 lg:px-8">

      <div class="grid">
        <div class="col-12 lg:col lg:px-5">
          <div class="flex flex-wrap align-items-center justify-content-between mb-5 gap-5">
            <div class="flex flex-row">
              <span class="text-900 surface-100 inline-flex font-semibold py-2 px-3 mr-2 border-round">News</span>
            </div>
            <div class="flex align-items-center gap-4">
              <p>October 2022</p>
            </div>
          </div>

          <div class="flex flex-wrap">
            <div class="w-full lg:w-12 pr-0 lg:pr-5">
              <div class="font-normal text-2xl mt-3 mb-3 text-900">Article Title</div>
              <p class="line-height-3 mt-0 mb-5">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc eget massa purus. Aenean maximus mollis velit. Donec congue interdum volutpat. Phasellus pellentesque libero ac odio euismod, non porta turpis gravida. Nulla congue lorem nec malesuada malesuada. Fusce maximus nisl tristique lacus viverra luctus. Nullam vel consectetur nunc. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vestibulum accumsan velit erat, quis consectetur diam venenatis a. Suspendisse potenti. Donec hendrerit nulla ut sem mollis, at venenatis justo tempus. Fusce volutpat ex in nulla molestie condimentum. Nulla sollicitudin magna tortor, a interdum tortor vestibulum sit amet. Nullam faucibus accumsan nisi, quis semper justo vestibulum tincidunt.
              </p>
              <p class="line-height-3 mt-0 mb-5">
                Praesent rhoncus, felis vitae tempus auctor, orci risus aliquam urna, nec rutrum massa ante vitae odio. Ut condimentum erat mauris, id egestas mi tincidunt et. Curabitur vel cursus risus. Proin suscipit cursus velit. Etiam imperdiet, massa vitae lobortis tempus, lectus mi pulvinar mauris, sit amet vehicula nulla ipsum sit amet purus. Nam tempor suscipit mi eu dictum. Curabitur sit amet eros quam. Donec eget dapibus tellus, vel tincidunt eros.
              </p>
              <p class="line-height-3  mt-0 mb-5">
                Convallis tellus id interdum velit laoreet id donec ultrices. Lacus sed viverra tellus in hac habitasse platea dictumst.
                Ultricies tristique nulla aliquet enim. Nunc scelerisque viverra mauris in aliquam sem fringilla. Laculis urna id volutpat lacus laoreet.
              </p>
              <p class="line-height-3 mt-0 mb-5">
                Nisi est sit amet facilisis. Ac odio tempor orci dapibus ultrices in iaculis nunc sed. Pellentesque pulvinar pellentesque habitant morbi tristique senectus.
                Nullam vehicula ipsum a arcu cursus vitae congue. Leo urna molestie at elementum eu facilisis. Fusce id velit ut tortor. Purus non enim praesent elementum facilisis leo vel fringilla est.
                Semper risus in hendrerit gravida rutrum quisque non pharetra convallis posuere morbi leo urna molestie at.
              </p>
              <p class="line-height-3 mt-0 mb-5">
                Nam non porttitor felis, at mollis orci. Morbi non diam quis nulla gravida facilisis id eget justo. Vestibulum ipsum orci, efficitur vitae velit eu, ultrices lacinia turpis. Sed eget luctus risus. Integer ut lacinia nulla. Vivamus et lobortis leo. Etiam faucibus ultricies justo, ut volutpat metus consequat commodo. Suspendisse at pellentesque sem. Phasellus non nunc tristique, fringilla felis ac, scelerisque ex.
              </p>
            </div>
            <div class="w-full lg:w-4 pl-0 lg:pl-5 pt-0">


            </div>


          </div>

        </div>
        <div class="col-12 lg:col-3 lg:border-left-1 surface-border">
          <div class="p-3">
            <span class="text-900 font-medium text-xl block mb-5">Other Posts</span>
            <div class="flex pb-4">
              <img :src="squarePlaceholder" class="mr-3 h-5rem w-5rem" />
              <div class="flex flex-column align-items-start">
                <span class="text-lg text-900 font-medium mb-1">Post Title</span>
                <span class="text-600 font-medium mb-2">Metus aliquam eleifend mi in nulla posuere.</span>
              </div>
            </div>
            <div class="flex pb-4">
              <img :src="squarePlaceholder" class="mr-3 h-5rem w-5rem" />
              <div class="flex flex-column align-items-start">
                <span class="text-lg text-900 font-medium mb-1">Post Title</span>
                <span class="text-600 font-medium mb-2">Metus aliquam eleifend mi in nulla posuere.</span>
              </div>
            </div>
            <div class="flex pb-4">
              <img :src="squarePlaceholder" class="mr-3 h-5rem w-5rem" />
              <div class="flex flex-column align-items-start">
                <span class="text-lg text-900 font-medium mb-1">Post Title</span>
                <span class="text-600 font-medium mb-2">Metus aliquam eleifend mi in nulla posuere.</span>
              </div>
            </div>
            <div class="flex flex-column gap-3">
              <div class="w-full h-full p-5 border-round" style="background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, #000000 100%), ('images/blocks/content/content-8.png')">
                <span class="text-white font-medium mb-3">Post Title</span>
                <span class="text-white block line-height-3">Metus aliquam eleifend mi innulla posuere.</span>
              </div>
              <div class="w-full h-full p-5 border-round" style="background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, #000000 100%), ('images/blocks/content/content-9.png')">
                <span class="text-white font-medium mb-3">Post Title</span>
                <span class="text-white block line-height-3">Metus aliquam eleifend mi innulla posuere.</span>
              </div>
              <div class="w-full h-full p-5 border-round" style="background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, #000000 100%), ('images/blocks/content/content-10.png')">
                <span class="text-white font-medium mb-3">Post Title</span>
                <span class="text-white block line-height-3">Metus aliquam eleifend mi in nulla posuere.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </standard-wrapper>
</template>

<script>

import StandardWrapper from "@/components/StandardWrapper";
import HeroImage from "@/components/HeroImage";
import LatestNewsAndEventsPanel from "@/components/LatestNewsAndEventsPanel";
import BookingCallToAction from "@/components/BookingCallToAction";
import HomeIntroduction from "@/components/HomeIntroduction";

export default {
  name: 'NewsAndEventsPage',

  props: {},

  components: {
    StandardWrapper, HeroImage, LatestNewsAndEventsPanel, BookingCallToAction, HomeIntroduction
  },

  data() {
    return {
      bannerImage: require("../assets/images/hero-images/marae-banner-placeholder.jpg"),
      squarePlaceholder: require("../assets/images/hero-images/square-placeholder.jpg"),
      personImage: require("../assets/images/placeholder/person.png"),

      categories: [{name: "News & Events"}, { name: "News Only"}, { name: "Events Only"}],
      selectedCategory: "News & Events",
    }
  },

  methods: {
    openStory: function(route){
      this.$router.push('/news-and-events/' + route);
    }

  },

  computed: {},

  watch: {}

}

</script>

<style scoped>
  .page-selector{
    width: 100%;
  }
</style>
