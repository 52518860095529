<template>
  <standard-wrapper :key="this.$store.state.userIsAuthenticated">

    <hero-image :image-src="bannerImage" main-title="Meet our People" sub-title="Learn about the leaders, members, and supporters of Tūmapūhia Marae, and find out how to contact them."></hero-image>

    <div class="surface-ground px-4 py-8 md:px-6 lg:px-8 text-700">
      <div class="flex flex-wrap">
        <div class="w-full xl:w-5 pr-0 lg:pr-5">
          <div class="font-normal text-2xl mt-3 mb-3 text-900">Meet the team at Tūmapūhia Marae</div>
          <p class="line-height-3 mt-0 mb-5">
            At Tūmapūhia Marae, we are proud to have a dedicated team of individuals who work together to serve the hapū and provide a welcoming and inclusive space for all members of the community. Our team includes experienced and knowledgeable individuals who are passionate about the history and culture of Tūmapūhia hapū.
          </p>
          <p class="line-height-3 text-600 text-xl mb-5" style="font-family">
            “The people are the heart of a community; it is their work, their passion, and their dedication that make a place truly special.”
          </p>
          <p class="line-height-3  mt-0 mb-5">
            Whether you're looking to book facilities for an event, learn more about the history of the hapū, or simply want to get in touch with one of our team members, we are here to help. Our team is committed to providing excellent customer service and ensuring that your experience at Tūmapūhia Marae is a positive one.
          </p>
          <p class="line-height-3  mt-0 mb-5">
            If you have any questions or would like to learn more about the services we offer at Tūmapūhia Marae, please don't hesitate to contact us. You can find the contact information for our team members on this page, and we look forward to hearing from you.
          </p>
        </div>
        <div class="w-full xl:w-2 xl:px-5 xl:py-5 hidden xl:block">
          <Divider layout="vertical" />
        </div>
        <div class="w-full xl:w-5 pl-0 lg:pl-5 pt-5">
          <div class="grid">
            <div class="col-12 lg:col-6 xl:col-4">

              <div class="shadow-2 p-3 mb-5 lg:mb-0 mr-0 lg:mr-5 surface-card">
                <img :src="personImage" class="mb-3 w-full" />
                <div class="flex justify-content-between align-items-center">
                  <div class="pr-3">
                    <div class="text-md text-900 font-medium mb-2">John Doe</div>
                    <div class="text-xs text-blue-600">Job Title</div>
                  </div>
                  <div class="flex flex-nowrap">
                    <Button class="p-button-rounded p-button-text p-button-outlined p-button-plain mr-2" icon="pi pi-envelope"></Button>
                  </div>
                </div>
              </div>

            </div>
            <div class="col-12 lg:col-6 xl:col-4">

              <div class="shadow-2 p-3 mb-5 lg:mb-0 mr-0 lg:mr-5 surface-card">
                <img :src="personImage" class="mb-3 w-full" />
                <div class="flex justify-content-between align-items-center">
                  <div class="pr-3">
                    <div class="text-md text-900 font-medium mb-2">John Doe</div>
                    <div class="text-xs text-blue-600">Job Title</div>
                  </div>
                  <div class="flex flex-nowrap">
                    <Button class="p-button-rounded p-button-text p-button-outlined p-button-plain mr-2" icon="pi pi-envelope"></Button>
                  </div>
                </div>
              </div>

            </div>
            <div class="col-12 lg:col-6 xl:col-4">

              <div class="shadow-2 p-3 mb-5 lg:mb-0 mr-0 lg:mr-5 surface-card">
                <img :src="personImage" class="mb-3 w-full" />
                <div class="flex justify-content-between align-items-center">
                  <div class="pr-3">
                    <div class="text-md text-900 font-medium mb-2">Jane Doe</div>
                    <div class="text-xs text-blue-600">Job Title</div>
                  </div>
                  <div class="flex flex-nowrap">
                    <Button class="p-button-rounded p-button-text p-button-outlined p-button-plain mr-2" icon="pi pi-envelope"></Button>
                  </div>
                </div>
              </div>

            </div>

            <div class="col-12 lg:col-6 xl:col-4">

              <div class="shadow-2 p-3 mb-5 lg:mb-0 mr-0 lg:mr-5 surface-card">
                <img :src="personImage" class="mb-3 w-full" />
                <div class="flex justify-content-between align-items-center">
                  <div class="pr-3">
                    <div class="text-md text-900 font-medium mb-2">Jane Doe</div>
                    <div class="text-xs text-blue-600">Job Title</div>
                  </div>
                  <div class="flex flex-nowrap">
                    <Button class="p-button-rounded p-button-text p-button-outlined p-button-plain mr-2" icon="pi pi-envelope"></Button>
                  </div>
                </div>
              </div>

            </div>

            <div class="col-12 lg:col-6 xl:col-4">

              <div class="shadow-2 p-3 mb-5 lg:mb-0 mr-0 lg:mr-5 surface-card">
                <img :src="personImage" class="mb-3 w-full" />
                <div class="flex justify-content-between align-items-center">
                  <div class="pr-3">
                    <div class="text-md text-900 font-medium mb-2">John Doe</div>
                    <div class="text-xs text-blue-600">Job Title</div>
                  </div>
                  <div class="flex flex-nowrap">
                    <Button class="p-button-rounded p-button-text p-button-outlined p-button-plain mr-2" icon="pi pi-envelope"></Button>
                  </div>
                </div>
              </div>

            </div>

            <div class="col-12 lg:col-6 xl:col-4">

              <div class="shadow-2 p-3 mb-5 lg:mb-0 mr-0 lg:mr-5 surface-card">
                <img :src="personImage" class="mb-3 w-full" />
                <div class="flex justify-content-between align-items-center">
                  <div class="pr-3">
                    <div class="text-md text-900 font-medium mb-2">Jane Doe</div>
                    <div class="text-xs text-blue-600">Job Title</div>
                  </div>
                  <div class="flex flex-nowrap">
                    <Button class="p-button-rounded p-button-text p-button-outlined p-button-plain mr-2" icon="pi pi-envelope"></Button>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="surface-section w-full mt-5">
      <register-cta class="surface-section"></register-cta>
    </div>

  </standard-wrapper>
</template>

<script>

import StandardWrapper from "@/components/StandardWrapper";
import HeroImage from "@/components/HeroImage";
import LatestNewsAndEventsPanel from "@/components/LatestNewsAndEventsPanel";
import BookingCallToAction from "@/components/BookingCallToAction";
import HomeIntroduction from "@/components/HomeIntroduction";
import RegisterCta from "@/components/RegisterCta";

export default {
  name: 'PeoplePage',

  props: {},

  components: {
    StandardWrapper, HeroImage, LatestNewsAndEventsPanel, BookingCallToAction, HomeIntroduction, RegisterCta
  },

  data() {
    return {
      bannerImage: require("../assets/images/hero-images/marae-banner-placeholder.jpg"),
      personImage: require("../assets/images/placeholder/person.png"),
    }
  },

  methods: {},

  computed: {},

  watch: {}

}

</script>

<style>
</style>
