<template>
  <standard-wrapper :key="this.$store.state.userIsAuthenticated">

    <hero-image :image-src="bannerImage" main-title="Stay up-to-date with Tūmapūhia Marae " sub-title="Discover upcoming events and activities, and learn about our recent news "></hero-image>



    <div class="surface-ground px-4 py-8 md:px-6 lg:px-8">

      <div class="text-center">
        <Dropdown v-model="selectedCategory" :options="categories" optionLabel="name" optionValue="name" placeholder="Select a Category" />
      </div>

      <div class="surface-ground px-4 pb-8 pt-5 md:px-6 lg:px-8">

        <div class="flex flex-column xl:flex-row mb-4">
          <div class="flex shadow-2 surface-card border-round mr-0 xl:mr-4 mb-6 xl:mb-0 flex-column md:flex-row">
            <img :src="squarePlaceholder" alt="Image" class="border-round-left" style="max-height: 234px;">
            <div class="p-4">
              <div class="flex justify-content-between mb-3">
                <span class="text-primary-500 font-medum">News</span>
                <span class="text-500 font-medium">November 2022</span>
              </div>
              <div class="font-medium text-900 mb-3 line-height-3 ">News Story Title</div>
              <div class="line-height-3 text-700 text-sm mb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</div>
              <div class="flex align-items-center">
                <Button @click="openStory('test')" label="Read More" icon="pi pi-chevron-right" iconPos="right"></Button>
              </div>
            </div>
          </div>

          <div class="flex shadow-2 surface-card border-round flex-column md:flex-row">
            <img :src="squarePlaceholder" alt="Image" class="border-round-left" style="max-height: 234px;">
            <div class="p-4">
              <div class="flex justify-content-between mb-3">
                <span class="text-primary-500 font-medum">Event</span>
                <span class="text-500 font-medium">November 2022</span>
              </div>
              <div class="font-medium text-900 mb-3 line-height-3 ">Event Title</div>
              <div class="line-height-3 text-700 text-sm mb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</div>
              <div class="flex align-items-center">
                <Button @click="openStory('test')" label="Read More" icon="pi pi-chevron-right" iconPos="right"></Button>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-column xl:flex-row mb-4">
          <div class="flex shadow-2 surface-card border-round mr-0 xl:mr-4 mb-6 xl:mb-0 flex-column md:flex-row">
            <img :src="squarePlaceholder" alt="Image" class="border-round-left" style="max-height: 234px;">
            <div class="p-4">
              <div class="flex justify-content-between mb-3">
                <span class="text-primary-500 font-medum">Event</span>
                <span class="text-500 font-medium">October 2022</span>
              </div>
              <div class="font-medium text-900 mb-3 line-height-3 ">Event Title</div>
              <div class="line-height-3 text-700 text-sm mb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</div>
              <div class="flex align-items-center">
                <Button @click="openStory('test')" label="Read More" icon="pi pi-chevron-right" iconPos="right"></Button>
              </div>
            </div>
          </div>

          <div class="flex shadow-2 surface-card border-round flex-column md:flex-row">
            <img :src="squarePlaceholder" alt="Image" class="border-round-left" style="max-height: 234px;">
            <div class="p-4">
              <div class="flex justify-content-between mb-3">
                <span class="text-primary-500 font-medum">Event</span>
                <span class="text-500 font-medium">October 2022</span>
              </div>
              <div class="font-medium text-900 mb-3 line-height-3 ">Event Title</div>
              <div class="line-height-3 text-700 text-sm mb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</div>
              <div class="flex align-items-center">
                <Button @click="openStory('test')" label="Read More" icon="pi pi-chevron-right" iconPos="right"></Button>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-column xl:flex-row mb-4">
          <div class="flex shadow-2 surface-card border-round mr-0 xl:mr-4 mb-6 xl:mb-0 flex-column md:flex-row">
            <img :src="squarePlaceholder" alt="Image" class="border-round-left" style="max-height: 234px;">
            <div class="p-4">
              <div class="flex justify-content-between mb-3">
                <span class="text-primary-500 font-medum">News</span>
                <span class="text-500 font-medium">October 2022</span>
              </div>
              <div class="font-medium text-900 mb-3 line-height-3 ">News Story Title</div>
              <div class="line-height-3 text-700 text-sm mb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</div>
              <div class="flex align-items-center">
                <Button @click="openStory('test')" label="Read More" icon="pi pi-chevron-right" iconPos="right"></Button>
              </div>
            </div>
          </div>

          <div class="flex shadow-2 surface-card border-round flex-column md:flex-row">
            <img :src="squarePlaceholder" alt="Image" class="border-round-left" style="max-height: 234px;">
            <div class="p-4">
              <div class="flex justify-content-between mb-3">
                <span class="text-primary-500 font-medum">Event</span>
                <span class="text-500 font-medium">August 2022</span>
              </div>
              <div class="font-medium text-900 mb-3 line-height-3 ">Event Title</div>
              <div class="line-height-3 text-700 text-sm mb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</div>
              <div class="flex align-items-center">
                <Button @click="openStory('test')" label="Read More" icon="pi pi-chevron-right" iconPos="right"></Button>
              </div>
            </div>
          </div>
        </div>




        <div class="flex flex-column xl:flex-row mb-4">
          <Paginator :rows="10" :totalRecords="21" class="page-selector surface-ground"></Paginator>
        </div>
      </div>
    </div>



  </standard-wrapper>
</template>

<script>

import StandardWrapper from "@/components/StandardWrapper";
import HeroImage from "@/components/HeroImage";
import LatestNewsAndEventsPanel from "@/components/LatestNewsAndEventsPanel";
import BookingCallToAction from "@/components/BookingCallToAction";
import HomeIntroduction from "@/components/HomeIntroduction";

export default {
  name: 'NewsAndEventsPage',

  props: {},

  components: {
    StandardWrapper, HeroImage, LatestNewsAndEventsPanel, BookingCallToAction, HomeIntroduction
  },

  data() {
    return {
      bannerImage: require("../assets/images/hero-images/marae-banner-placeholder.jpg"),
      squarePlaceholder: require("../assets/images/hero-images/square-placeholder.jpg"),
      personImage: require("../assets/images/placeholder/person.png"),

      categories: [{name: "News & Events"}, { name: "News Only"}, { name: "Events Only"}],
      selectedCategory: "News & Events",
    }
  },

  methods: {
    openStory: function(route){
      this.$router.push('/news-and-events/' + route);
    }

  },

  computed: {},

  watch: {}

}

</script>

<style scoped>
  .page-selector{
    width: 100%;
  }
</style>
