<template>
  <swiper :slidesPerView="1"
          :spaceBetween="30"
          :loop="true"
          :pagination="{clickable: true,}"
          :navigation="true"
          :modules="modules" class="homepage-swiper">
    <swiper-slide>
      <div class="hero-image expanded" :style="'background-image: url(\'' + image1 + '\');'"></div>
    </swiper-slide>
    <swiper-slide>
      <div class="hero-image expanded" :style="'background-image: url(\'' + image2 + '\');'"></div>
    </swiper-slide>
    <swiper-slide>
      <div class="hero-image expanded" :style="'background-image: url(\'' + image3 + '\');'"></div>
    </swiper-slide>
    <swiper-slide>
      <div class="hero-image expanded" :style="'background-image: url(\'' + image4 + '\');'"></div>
    </swiper-slide>
  </swiper>
</template>

<script>

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// import required modules
import { Pagination, Navigation } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

export default {
  name: 'HeroImage',
  props: {},

  components: {
    Swiper, SwiperSlide
  },

  data() {
    return {
      modules: [Pagination, Navigation],
      image1: require("../assets/images/hero-images/marae-banner-placeholder.png"),
      image2: require("../assets/images/hero-images/marae-banner-placeholder.png"),
      image3: require("../assets/images/hero-images/marae-banner-placeholder.png"),
      image4: require("../assets/images/hero-images/marae-banner-placeholder.png"),
    }
  },

  methods: {},

  computed: {

  },

  watch: {}

}
</script>


<style scoped>

.hero-image {
  /* Set a specific height */
  height: 500px;

  /* Position and center the image to scale nicely on all screens */
  background-position: 50% 60%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  border-radius: 3px;
}

.expanded{
  margin: -2px;
}

.homepage-swiper{
  margin: -2px;
  z-index: 1;
}




</style>
