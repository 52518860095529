<template>
  <standard-wrapper :key="this.$store.state.userIsAuthenticated">

    <hero-image :image-src="bannerImage" main-title="Book our Facilities" sub-title="Easily schedule events and activities at Tumapuhia Marae with our online booking system."></hero-image>

    <div class="surface-ground px-4 py-8 md:px-6 lg:px-8">
      <div class="font-bold text-900 text-3xl mb-3 text-center">Facilities</div>
      <div class="text-700 text-center mb-3">Risus feugiat in ante metus. Lacus suspendisse faucibus interdum posuere lorem.</div>
      <div class="flex flex-wrap">
        <div class="w-full lg:w-6 xl:w-3 p-5">
          <img :src="bannerImage" alt="Image" class="w-full">
          <div class="mt-3 mb-2 font-medium text-900 text-xl">Ornare Arcu Odio</div>
          <span class="text-700 line-height-3">Quisque sagittis purus sit amet volutpat consequat mauris nunc. Scelerisque eleifend.</span>
        </div>
        <div class="w-full lg:w-6 xl:w-3 p-5">
          <img :src="bannerImage" alt="Image" class="w-full">
          <div class="mt-3 mb-2 font-medium text-900 text-xl">Sem Nulla Pharetra</div>
          <span class="text-700 line-height-3">Quisque sagittis purus sit amet volutpat consequat mauris nunc. Scelerisque eleifend.</span>
        </div>
        <div class="w-full lg:w-6 xl:w-3 p-5">
          <img :src="bannerImage" alt="Image" class="w-full">
          <div class="mt-3 mb-2 font-medium text-900 text-xl">Facilisi Etiam Dignissim</div>
          <span class="text-700 line-height-3">Quisque sagittis purus sit amet volutpat consequat mauris nunc. Scelerisque eleifend.</span>
        </div>
        <div class="w-full lg:w-6 xl:w-3 p-5">
          <img :src="bannerImage" alt="Image" class="w-full">
          <div class="mt-3 mb-2 font-medium text-900 text-xl">Risus Feugiat In Ante</div>
          <span class="text-700 line-height-3">Quisque sagittis purus sit amet volutpat consequat mauris nunc. Scelerisque eleifend.</span>
        </div>
      </div>
    </div>


    <div class="surface-section px-4 py-0 md:px-6 lg:px-8">

      <div v-if="0" class="flex justify-content-center flex-column lg:flex-row mt-5">
        <div class="shadow-2 p-1 m-1 lg:mb-0 mr-0 lg:mr-5 surface-card">
          <img :src="bannerImage" class="w-full h-full" />
        </div>
        <div class="shadow-2 p-1 m-1 lg:mb-0 mr-0 lg:mr-5 surface-card">
          <img :src="bannerImage" class="w-full h-full" />
        </div>
        <div class="shadow-2 p-1 m-1 lg:mb-0 mr-0 lg:mr-5 surface-card">
          <img :src="bannerImage" class="w-full h-full" />
        </div>
        <div class="shadow-2 p-1 m-1 lg:mb-0 mr-0 surface-card">
          <img :src="bannerImage" class="w-full h-full" />
        </div>
      </div>

      <div class="flex justify-content-center flex-column lg:flex-row">

        <div class="grid grid-nogutter px-4 py-0 md:px-6 lg:px-8">
          <div class="col-12 md:col-6 bg-no-repeat bg-cover pt-8 lg:p-8">
            <h2 >Availability</h2>
            <p class="mb-5">Check your preferred dates are free before booking. A red background indicates that we are unavailable on that day.</p>
            <Divider class="mb-5" />
            <BookingCalendar></BookingCalendar>

          </div>
          <div class="col-12 md:col-6">
            <h2 class="px-4 pt-8 pb-0 mb-0 md:px-6 lg:px-8 ">Booking Request</h2>
            <div class="p-fluid formgrid grid px-4 pt-4 pb-8 md:px-6 lg:px-8">
              <div class="field col-12 lg:col-6 p-float-label mb-4">
                <InputText id="firstname" type="text" class="py-3 px-2 text-lg" placeholder="First Name" />
              </div>
              <div class="field col-12 lg:col-6 p-float-label mb-4">
                <InputText id="lastname" type="text"  class="py-3 px-2 text-lg" placeholder="Last Name" />
              </div>
              <div class="field col-12 lg:col-6 p-float-label mb-4">
                <InputText id="email" type="text"  class="py-3 px-2 text-lg" placeholder="Email" />
              </div>
              <div class="field col-12 lg:col-6 p-float-label mb-4">
                <InputText id="phone" type="text"  class="py-3 px-2 text-lg" placeholder="Phone" />
              </div>
              <div class="field col-12 mb-4">
                <InputText id="organisation" type="text"  class="py-3 px-2 text-lg" placeholder="Organisation" />
              </div>
              <div class="field col-12 mb-4">
                <Textarea id="message" :rows="14" :autoResize="true" class="py-3 px-2 text-lg" placeholder="Enquiry

Please include the type of event and date(s) you wish to book for.

Check the booking calendar for available dates."></Textarea>
              </div>
              <div class="col-12 text-right">
                <Button label="Submit" icon="pi pi-envelope" class="px-5 py-3 w-auto"></Button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="flex justify-content-center flex-column lg:flex-row">




      <div class="px-4 py-6 md:px-6">
        <div class="text-2xl text-900 font-bold mb-2">More Information</div>
        <div class="text-700 line-height-3">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>

        <div class="grid mt-6">
          <div class="col-12 text-900 md:col-6 font-medium text-lg line-height-3">Who can book?</div>
          <div class="col-12 md:col-6 text-700 line-height-3">Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</div>
        </div>
        <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
        <div class="grid">
          <div class="col-12 text-900 md:col-6 font-medium text-lg line-height-3">How much does it cost?</div>
          <div class="col-12 md:col-6 text-700 line-height-3">Sed cras ornare arcu dui. Placerat in egestas erat imperdiet. Arcu vitae elementum curabitur vitae nunc sed velit dignissim.</div>
        </div>
        <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
        <div class="grid">
          <div class="col-12 text-900 md:col-6 font-medium text-lg line-height-3">What are the marae rules?</div>
          <div class="col-12 md:col-6 text-700 line-height-3">In hac habitasse platea dictumst vestibulum. Duis ultricies lacus sed turpis tincidunt id. Imperdiet proin fermentum leo vel.</div>
        </div>
        <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
        <div class="grid">
          <div class="col-12 text-900 md:col-6 font-medium text-lg line-height-3">What kind of events can we facilitate?</div>
          <div class="col-12 md:col-6 text-700 line-height-3">Cras ornare arcu dui vivamus arcu felis bibendum ut. Amet purus gravida quis blandit turpis cursus in hac habitasse. Leo in vitae turpis massa sed elementum tempus. </div>
        </div>
        <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
        <div class="grid">
          <div class="col-12 text-900 md:col-6 font-medium text-lg line-height-3">When will I hear back about my booking request?</div>
          <div class="col-12 md:col-6 text-700 line-height-3">Cras ornare arcu dui vivamus arcu felis bibendum ut. Amet purus gravida quis blandit turpis cursus in hac habitasse. Leo in vitae turpis massa sed elementum tempus. </div>
        </div>
      </div>

    </div>
  </standard-wrapper>
</template>

<script>

import StandardWrapper from "@/components/StandardWrapper";
import HeroImage from "@/components/HeroImage";
import BookingCalendar from "@/components/BookingCalendar";
import ImageScroller from "@/components/ImageScroller";

export default {
  name: 'PeoplePage',

  props: {},

  components: {
    StandardWrapper, HeroImage, BookingCalendar, ImageScroller
  },

  data() {
    return {
      bannerImage: require("../assets/images/hero-images/marae-banner-placeholder.png"),
      personImage: require("../assets/images/placeholder/person.png"),
    }
  },

  methods: {},

  computed: {},

  watch: {}

}

</script>

<style scoped>


</style>
