<template>
  <standard-wrapper :key="this.$store.state.userIsAuthenticated">

<!--    <hero-image :image-src="bannerImage"></hero-image>-->

    <hero-slider></hero-slider>

    <home-introduction></home-introduction>

    <div class="grid">
      <div class="col-12 px-6 mb-8 mt-4">
        <p><span style="color: orangered; text-decoration: underline">Homepage is not mocked up yet.</span><br> Some possible options could include:</p>
        <ul>
          <li>A summary of the Marae's mission and purpose, to provide context and background information about the Marae and its activities</li>
          <li>A list of upcoming events and activities, to provide users with a quick and easy way to see what is happening at the Marae in the near future</li>
          <li>A photo gallery or slideshow, to showcase the Marae's facilities and activities in a visual and engaging way</li>
          <li>A map or location information, to help users find the Marae and plan their visit</li>
          <li>A call to action, to encourage users to take a specific action, such as booking a facility or joining the Marae</li>
          <li>A contact form or email address, to provide users with a way to easily get in touch with the Marae</li>
          <li>Links to social media accounts or other relevant websites, to provide users with additional information and resources.</li>
        </ul>
      </div>


<!--      <div class="col-12 lg:col-6">
        <news-widget></news-widget>
      </div>
      <div class="col-12 lg:col-6">
        <events-widget></events-widget>
      </div>

      <div class="col-12">
        <register-cta></register-cta>
      </div>-->
    </div>


<!--    <booking-call-to-action></booking-call-to-action>-->



  </standard-wrapper>
</template>

<script>

import StandardWrapper from "@/components/StandardWrapper";
import HeroImage from "@/components/HeroImage";
import NewsWidget from "@/components/NewsWidget";
import EventsWidget from "@/components/EventsWidget";
import RegisterCta from "@/components/RegisterCta";
import BookingCallToAction from "@/components/BookingCallToAction";
import HomeIntroduction from "@/components/HomeIntroduction";
import HeroSlider from "@/components/HeroSlider";

export default {
  name: 'HomePage',

  props: {},

  components: {
    StandardWrapper, HeroImage, BookingCallToAction, HomeIntroduction, HeroSlider, NewsWidget, EventsWidget, RegisterCta
  },

  data() {
    return {
      bannerImage: require("../assets/images/hero-images/marae-banner-placeholder.jpg"),
/*      heroMainTitle: "Nau mai, haere mai",
      heroSubTitle: "Our marae, Tūmapūhia hapū"*/
    }
  },

  methods: {},

  computed: {},

  watch: {}

}

</script>

<style>
</style>
