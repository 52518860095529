<template>
  <div class="bg-gray-900 px-4 py-6 md:px-6 lg:px-8 bg-no-repeat bg-cover md:bg-contain" :style="'background-image: url(\'' + bookingImage + '\'); background-position: right;'">
    <div class="grid">
      <div class="col-12 md:col-6">
        <Button label="Booking Calendar" icon="pi pi-chevron-right" iconPos="right" class="p-button-primary p-button-rounded mb-3 font-bold"></Button>
        <div class="text-white font-bold text-3xl mb-3">Book your visit</div>
        <div class="text-gray-400 text-xl line-height-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
        <div class="text-gray-400 text-xl line-height-3">Suspendisse non nulla volutpat lectus tincidunt tincidunt.</div>
      </div>
      <div class="col-12 md:col-6 flex align-items-center md:justify-content-end">
        <div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BookingCalendar from "@/components/BookingCalendar";

export default {
  name: 'BookingCallToAction',
  props: {},

  components: {
    BookingCalendar
  },

  data() {
    return {
      bookingImage: require("../assets/images/placeholder/marae-interior.png"),
    }
  },

  methods: {},

  computed: {},

  watch: {}

}
</script>


<style scoped>


</style>
