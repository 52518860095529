<template>

  <div class="surface-ground px-4 pt-8 pb-2 md:px-6 lg:px-8">
    <div class="font-bold text-900 text-5xl mb-3 text-center">Nau mai, haere mai</div>
    <div class="text-xl line-height-3 text-600 mb-6 text-center">
      Welcome to Tūmapūhia hapū! We are a community organization dedicated to preserving and promoting the traditions and culture of our iwi. On our website, you can find information about the Marae, our people, and our latest news and events. You can also book a time slot at the Marae, register as a member, and access exclusive resources and documents. Explore our website to learn more about us and how you can get involved.
    </div>
  </div>

</template>

<script>


export default {
  name: 'HomeIntroduction',
  props: {},

  components: {},

  data() {
    return {

    }
  },

  methods: {},

  computed: {},

  watch: {}

}
</script>


<style scoped>

.bottom-margin{
  margin-bottom: 29px;
}
</style>
